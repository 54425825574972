<template>
  <div class="main-wrap">
    <h4 class="title-h4">{{ isHavePSW ? "修改手机号" : "绑定手机号" }}</h4>
    <div class="Tip" v-show="nuxt">请设置您需要更换的手机号</div>
    <div class="Tip" v-show="!nuxt">为确认是您本人操作，请使用短信验证码认证</div>
    <div class="input-box" v-show="isHavePSW">
      <span v-show="!nuxt" class="name">当前手机号：{{ $store.state.userInfo.mobile }}</span>
      <div class="input-info" v-show="!nuxt">
        <span class="tip">验证码</span>
        <a-input class="input" v-model="oldcode" :maxLength="6" placeholder="请输入验证码" />

        <div class="button">
          <a-button type="link" @click="oldfunCode()" block>
            {{ oldshow ? "获取验证码" : oldcount + '秒后重新发送' }}
          </a-button>
        </div>
      </div>
      <div class="input-info" v-show="nuxt">
        <span class="tip">手机号</span>
        <a-input
          class="input"
          v-model="newNumber"
          :maxLength=11
          placeholder="请输入手机号"
        />
      </div>
      <div class="input-info" v-show="nuxt">
        <span class="tip">验证码</span>
        <a-input class="input" :maxLength="6" v-model="code" placeholder="请输入验证码" />

        <div class="button">
          <a-button type="link" @click="funCode()" block>
            {{ show ? "获取验证码" : count + '秒后重新发送' }}
          </a-button>
        </div>
      </div>
    </div>

    <div class="btn-foot">
      <p v-show="nuxt" class="cancel" @click="nuxt = false">取消</p>
      <p v-show="nuxt" class="all-btn-small" @click="submit()">确认</p>
      <p v-show="!nuxt" class="all-btn-small" @click="inputChange()">下一步</p>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      isPublicTipsShow: false,

      isHavePSW: false,
      tipsIndex: null,
      newNumber: "", //新手机号
      confirm: "", //确认密码
      code: "", //验证码
      count: "", //倒计时
      show: true,
      // 旧
      oldcode: "", //旧验证码
      oldcount: "", //旧倒计时
      oldshow: true,

      nuxt: false,

      popCode: [
        {
          id: "4",
          country: "中国",
          code: "+86",
        },
        {
          id: "1",
          country: "中国香港",
          code: "+852",
        },
        {
          id: "2",
          country: "中国澳门",
          code: "+853",
        },
        {
          id: "3",
          country: "中国台湾",
          code: "+886",
        },
      ],
      isLoading:false
    };
  },
  // 事件处理器
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.isLogin = true;
          this.$store.commit("updateUserInfo", res.data);
        }
      });
    },
    // 下一步
    inputChange() {
      if (!this.oldcode) {
        this.$message.warning("请输入验证码");
        return;
      } else if (!this.$regular.dxcode.reg.test(this.oldcode)) {
        this.$message.warning("请输入正确的验证码");
        return;
      }
      // 校验验证码
      this.$ajax({
        url: "/hxclass-pc/user/upMobile/vercode",
        method: "get",
        params: {
          mobile: this.$store.state.userInfo.mobile, // 手机号
          verCode: this.oldcode, // 验证码
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.nuxt = !this.nuxt;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 获取旧手机号的验证码
    oldfunCode() {
      if (this.oldshow != true) {
        return false;
      }
      // 获取验证码
      this.$ajax({
        url: "/hxclass-pc/user/update/old/phone/sms",
        method: "get",
        params: {
          phone: this.$store.state.userInfo.mobile, //手机号
          mobileType: this.popCode[0].id, //区号
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("发送成功");
          // 倒计时
          this.funSMS(0);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 获取新手机号的验证码
    funCode() {
      if (this.show != true) {
        return false;
      }
      // console.log(this.popCode[this.popIndex].id)
      if (!this.newNumber) {
        this.$message.warning("请输入手机号");
        return;
      } else if (!this.$regular.phone.reg.test(this.newNumber)) {
        this.$message.warning("请输入正确的手机号");
        return;
      }

      // 获取验证码
      this.$ajax({
        url: "/hxclass-pc/user/update/phone/sms",
        method: "get",
        params: {
          phone: this.newNumber, //手机号
          mobileType: this.popCode[0].id, //区号
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("发送成功");
          // 倒计时
          this.funSMS(1);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 确认更换
    submit() {
      if (!this.newNumber) {
        this.$message.warning("请输入手机号");
        return;
      } else if (!this.$regular.phone.reg.test(this.newNumber)) {
        this.$message.warning("请输入正确的手机号");
        return;
      } else if (!this.code) {
        this.$message.warning("请输入验证码");
        return;
      } else if (!this.$regular.dxcode.reg.test(this.code)) {
        this.$message.warning("请输入正确的验证码");
        return;
      }

      if(this.isLoading){return}
      this.isLoading = true;

      // 更换手机号
      this.$ajax({
        // url: '/hxclass-mobile/user/upmobile',
        url:
          "/hxclass-pc/user/upmobile?mobile=" +
          this.newNumber +
          "&verCode=" +
          this.code +
          "&userId=" +
          this.$store.state.userInfo.userId,
        method: "put",
        // params: {
        //   mobile: this.newNumber,
        //   verCode: this.code,
        //   userId:
        // }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("手机号更换成功");

          let timer = setTimeout(() => {
            this.isSignoutShow = false;
            this.$store.commit("clearUserInfo");
            this.$router.push({
              path: "/login/loginIndex?type=2",
              // query: {type: 2}
            });
            clearTimeout(timer);
          }, 1000);

          // let obj = JSON.parse(localStorage.getItem("userInfo"));
          // obj.mobile = this.newNumber;
          // this.$store.state.userInfo = obj;
          // localStorage.setItem("userInfo", JSON.stringify(obj));
          // // 保存手机号
          // localStorage.setItem("loginnewNumber", obj.mobile);
          // this.$router.go("-1");
        } else {
          this.$message.warning(res.message);
        }

        this.isLoading = false;

      });
    },
    // 倒计时
    funSMS(e) {
      if (e) {
        // 新手机号的倒计时和验证
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      } else {
        // 旧手机号的倒计时和验证
        const TIME_COUNT = 60;
        if (!this.oldtimer) {
          this.oldcount = TIME_COUNT;
          this.oldshow = false;
          this.oldtimer = setInterval(() => {
            if (this.oldcount > 0 && this.oldcount <= TIME_COUNT) {
              this.oldcount--;
            } else {
              this.oldshow = true;
              clearInterval(this.oldtimer);
              this.oldtimer = null;
            }
          }, 1000);
        }
      }
    },
    // 弹窗回调事件
    getEvent() {
      /** 需要执行的任务 **/
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 检查是否有密码
    this.isHavePSW = this.$store.state.userInfo.mobile ? true : false;
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.input-box {
  width: 472px;
  margin-top: 12px;
  .name {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
  }
  .input {
    margin-top: 12px;
    height: 44px;
  }
  /deep/ .paw {
    -webkit-text-security: disc !important;
  }
  .tips {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ff0000;
    img {
      position: relative;
      top: -2px;
      width: 13px;
      height: 13px;
      margin-right: 4px;
    }
  }
}
.btn-foot {
  margin-top: 40px;
  display: flex;
  .cancel {
    text-align: center;
    line-height: 40px;
    width: 138px;
    border-radius: 40px;
    height: 40px;
    color: @theme;
    margin-right: 54px;
    border: 1px solid @theme;
    cursor: pointer;
  }
}
.Tip {
  font-size: 24px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
}
.input-info {
  margin-top: 32px;
  position: relative;
  .tip {
    font-size: 18px;
    font-family: PingFang HK;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
    display: block;
  }
  .button {
    display: flex;
    align-items: center;
    width: 125px;
    height: 24px;
    position: absolute;
    bottom: 8px;
    right: 0;
    border-left: 1px solid #c4c4c4;
  }
}
@media screen and (max-width: 16000px) {
  .main-wrap {
    .title-h4 {
      font-size: 22px !important;
    }
  }
  .Tip {
    font-size: 20px !important;
  }
}
</style>
